import { FunctionComponent } from "react";

interface WidgetsProps {

}

const Widgets: FunctionComponent<WidgetsProps> = () => {
  return (
    <div className="widget-box">
      Widgets
    </div>
  );
}

export default Widgets;