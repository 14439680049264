import { FunctionComponent } from "react";

interface SettingsProps {
  
}
 
const Settings: FunctionComponent<SettingsProps> = () => {
  return ( 
    <div className="settings-box" >
      Settings!!
    </div>
   );
}
 
export default Settings;